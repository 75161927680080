import React from 'react';
import ReactDOM from 'react-dom';
import { data } from 'hudl-base';
import './display-ad.scss';

const GOOGLE_AD_REFRESH_INTERVAL = 60000; // 60 seconds in milliseconds
const REFRESH_TIMER = 5000; // 5 seconds in milliseconds

class DisplayAd extends React.Component {
    static defaultProps = {
        isMobile: false,
        disableAdWrapper: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            isAdBlocked: false,
            lastAdRefreshTime: null,
        };

        this.refreshTimer = null;
        this.refreshPaused = false;
        this.adContainerRef = null;
    }

    componentDidMount() {
        this.detectAdBlock();
        this.displayAdWhenReady();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.displayAdWhenReady();
        }
    }

    componentWillUnmount() {
        this.stopRefreshTimer();
        this.removeFocusAndBlurListeners();
    }

    getAdSlotByDomId = (domId) => {
        const slots = window.googletag.pubads().getSlots();
        const foundSlot = slots.find(slot => slot.getSlotId().getDomId() === domId);
        return foundSlot;
    };

    detectAdBlock = () => {
        fetch('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js', {
            method: 'HEAD',
            mode: 'no-cors',
        }).catch(() => {
            this.setState({ isAdBlocked: true });
        });
    };

    getPercentageVisible = () => {
        const node = ReactDOM.findDOMNode(this.adContainerRef);
        const rect = node.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        let visibility = 0;
        if (rect.bottom > windowHeight) {
            visibility = (windowHeight - rect.top) / rect.height;
        } else if (rect.top < 0) {
            visibility = rect.bottom / rect.height;
        } else {
            visibility = 1; // Fully visible
        }
        return (visibility * 100).toFixed(0);
    };

    startRefreshTimer = () => {
        this.refreshTimer = setInterval(() => {
            const percentageVisible = this.getPercentageVisible();
            const currentTime = Date.now();
            const lastRefreshTime = this.state.lastAdRefreshTime;
            const timeSinceLastRefresh = lastRefreshTime ? (currentTime - lastRefreshTime) : null;

            if (percentageVisible >= 51 && !this.refreshPaused && timeSinceLastRefresh >= GOOGLE_AD_REFRESH_INTERVAL) {
                googletag.cmd.push(() => {
                    this.refreshAdSlot();
                });
            }
        }, REFRESH_TIMER);
    };

    stopRefreshTimer = () => {
        clearInterval(this.refreshTimer);
    };

    refreshAdSlot = () => {
        let slot = this.getAdSlotByDomId(this.props.id);
        if (!slot) {
            slot = this.createSlot(this.props.id);
            googletag.display(this.props.id);
        }
        window.googletag.pubads().refresh([slot]);
        this.setState({ lastAdRefreshTime: Date.now() });
    };

    addFocusAndBlurListeners = () => {
        window.addEventListener('focus', this.resumeRefreshTimer);
        window.addEventListener('blur', this.pauseRefreshTimer);
    };

    removeFocusAndBlurListeners = () => {
        window.removeEventListener('focus', this.resumeRefreshTimer);
        window.removeEventListener('blur', this.pauseRefreshTimer);
    };

    pauseRefreshTimer = () => {
        this.refreshPaused = true;
    };

    resumeRefreshTimer = () => {
        this.refreshPaused = false;
    };

    displayAdWhenReady = () => {
        googletag.cmd.push(() => {
            this.refreshAdSlot();
            this.startRefreshTimer();
            this.addFocusAndBlurListeners();
        });
    };

    createSlot = (adId) => {
        const googletag = window.googletag;
        const adData = data.get('ads');
        const adPath = `/${adData.networkId}/Display/${this.props.path}`;
        let slot = googletag.defineSlot(adPath, this.props.sizes, adId).setCollapseEmptyDiv(true);

        Object.keys(adData.targetingData).forEach(key => {
            slot = slot.setTargeting(key, adData.targetingData[key]);
        });

        if (this.props.adSizeMapping) {
            let mapping = googletag.sizeMapping();
            this.props.adSizeMapping.forEach(value => {
                mapping = mapping.addSize(value.browser, value.ad);
            });
            slot = slot.defineSizeMapping(mapping.build());
        }

        slot = slot.addService(googletag.pubads());
        return slot;
    };


    render() {
        const { id, isMobile, disableAdWrapper } = this.props;
        const { isAdBlocked } = this.state;

        return disableAdWrapper || isAdBlocked ? (
            <div id={id} ref={el => (this.adContainerRef = el)} />
        ) : (
            <div className={isMobile ? 'mobile-ad-container' : ''}>
                <div id={id} ref={el => (this.adContainerRef = el)} style={!isMobile ? { width: 300, height: 250 } : {}} />
            </div>
        );
    }
}

export default DisplayAd;
